import { localStoragePrefix } from '@/config/constVars';
const i18nService = {
  defaultLanguage: 'ar',

  languages: [
    {
      lang: 'en',
      name: 'English',
      flag: process.env.BASE_URL + 'media/svg/flags/united-states.svg',
    },
    {
      lang: 'ar',
      name: 'العربية',
      flag: process.env.BASE_URL + 'media/svg/flags/saudi-arabia.svg',
    },
  ],

  setActiveLanguage(lang) {
    localStorage.setItem(localStoragePrefix + 'language', lang);
  },

  getActiveLanguage() {
    return (
      localStorage.getItem(localStoragePrefix + 'language') ||
      this.defaultLanguage
    );
  },
};

export default i18nService;
