<template>
  <v-app-bar app flat style="position:static;">
    <Container>
      <v-row align="center">
        <router-link to="/">
          <v-img
            alt="4talents Logo"
            :src="require('@/assets/logo.png')"
            contain
            height="48"
            width="48"
            max-width="48"
          />
        </router-link>

        <div class="mx-5" />
        <v-row class="hidden-sm-and-down">
          <base-card
            v-for="(link, i) in links"
            :key="i"
            height="60px"
            width="60px"
            :to="link.to"
            class="navlink d-flex flex-column justify-space-around align-center white mx-2 py-1"
          >
            <!-- style="backgroundColor:#F1854D" -->
            <div>
              <v-icon :color="link.color">{{ link.icon }}</v-icon>
            </div>
            <div style="fontSize:13px;" class="navlink-text primary--text">
              {{ $t('NAV.' + link.text) }}
            </div>
          </base-card>
        </v-row>

        <v-spacer />

        <v-btn icon @click="$router.push('/wishlist')">
          <v-badge
            overlap
            color="secondary"
            :content="
              (cartGetter.favorite && cartGetter.favorite.length) || '0'
            "
          >
            <v-icon color="primary">mdi-heart-outline</v-icon>
          </v-badge>
        </v-btn>

        <!-- <v-btn icon @click="$router.push('/cart')">
          <v-badge
            overlap
            color="secondary"
            :content="(cartGetter.cart && cartGetter.cart.length) || '0'"
          >
            <v-icon color="primary">mdi-cart</v-icon>
          </v-badge>
        </v-btn> -->

        <!-- profile -->
        <template v-if="userGetter.isAuth">
          <!-- <span @click="logoutAction">Logout</span> -->
          <v-menu bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon dark v-bind="attrs" v-on="on">
                <v-icon style="fontSize:30px" color="primary"
                  >mdi-account-circle</v-icon
                >
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                style="minHeight:35px !important;"
                to="/profile"
                :class="$vuetify.rtl ? 'text-right' : 'text-left'"
              >
                <v-list-item-title>{{ $t('profile') }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                style="minHeight:35px !important;"
                to="/my-tests?type=2"
                :class="$vuetify.rtl ? 'text-right' : 'text-left'"
              >
                <v-list-item-title>{{ $t('myTests') }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                style="minHeight:35px !important;"
                to="/my-courses?type=1"
                :class="$vuetify.rtl ? 'text-right' : 'text-left'"
              >
                <v-list-item-title>{{ $t('myLearning') }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                style="minHeight:35px !important;"
                :class="$vuetify.rtl ? 'text-right' : 'text-left'"
                @click="logout"
              >
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-else>
          <v-btn class="ml-2" min-width="0" text @click="showAuthForm = true">
            <v-icon color="primary">mdi-login</v-icon>
            <!-- {{ $t('AUTH.login') }} -->
          </v-btn>
        </template>
        <Auth
          v-if="showAuthForm"
          :dialog="showAuthForm"
          @closeDialog="showAuthForm = false"
        />

        <!-- language -->
        <v-menu
          rounded
          left
          bottom
          offset-y
          close-on-click
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="mx-1" text v-bind="attrs" v-on="on">
              <div class="body-1 primary--text">
                {{ $root.lang == 'ar' ? 'العربية' : 'English' }}
              </div>

              <!-- <img
                style="width:20px;heigth:20px;borderRadius:4px"
                :src="languageFlag || getLanguageFlag"
                alt=""
              /> -->
            </v-btn>
          </template>

          <v-list style="max-width: 80px;" class="langs-list">
            <v-list-item
              style="width: 80"
              link
              v-for="(lang, index) in languages"
              :key="index"
              @click="selectedLanguage(lang.lang)"
            >
              <div class="body-1 primary--text">{{ lang.name }}</div>
              <!-- <img alt="" :src="lang.flag" /> -->
            </v-list-item>
          </v-list>
        </v-menu>

        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          color="primary"
          @click="toggleDrawer"
        />
      </v-row>
    </Container>
  </v-app-bar>
</template>

<script>
// Utilities
import i18nService from '@/services/i18n.service.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'CoreAppBar',
  components: {
    Auth: () => import('@/components/Auth'),
  },

  data() {
    return {
      languageFlag: null,
      languages: i18nService.languages,
      showAuthForm: false,
      menu: ['Profile', 'Indicators', 'My Courses'],
    };
  },

  computed: {
    ...mapGetters(['links', 'userGetter', 'cartGetter']),
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },

  methods: {
    ...mapMutations(['toggleDrawer']),
    ...mapActions(['logoutAction', 'getCartItemsAction']),
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    selectedLanguage(lang) {
      i18nService.setActiveLanguage(lang);
      this.onLanguageChanged(
        this.languages.find((val) => {
          return val.lang === lang;
        })
      );
      window.dispatchEvent(
        new CustomEvent('langChange', {
          detail: {
            newLang: lang,
          },
        })
      );
      window.location.reload();
    },
    // onClick(e, item) {
    //   e.stopPropagation();

    //   // if (item.to || !item.href) return;

    //   if (item.to) {
    //     this.$router.push(item.to);
    //   } else {
    //     this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href);
    //   }
    // },
    logout() {
      this.logoutAction();
      this.$router.push('/');
      // window.location.reload();
    },
  },
  mounted() {
    this.getCartItemsAction('cart');
    this.getCartItemsAction('favorite');
  },
};
</script>

<style scoped>
/* .parent-element >>> .vuetify-class {
  // values
} */

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  background: var(--v-greyBg-base) !important;
  height: 80px !important;
}

.v-sheet.v-app-bar.v-toolbar >>> .v-toolbar__content {
  height: 100% !important;
}

.navlink {
  border-radius: 8px !important;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.navlink.router-link-exact-active .navlink-text,
.navlink.router-link-exact-active .v-icon,
.navlink.router-link-exact-active {
  color: #f46727 !important;
  background: rgba(244, 103, 39, 0.05) !important;
  /* background: rgba(244, 103, 39, 0.5) !important; */
}

.navlink:hover {
  background: rgba(244, 103, 39, 0.2) !important;
}
</style>

//
<style lang="scss" scoped>
//   .vuetify-class {
//     ::v-deep other-class {
//       // your custom css properties
//     }
//   }
//
</style>
